import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../redux/Store';
import { isUserAuthenticated } from '../utils/authUtils';
import { PATHS } from './Routes';
import { PageLoader } from '../components/Loaders/Loader';
import CompleteRegistration from '../pages/Auth/CompleteRegistration';
import ErrorPage from '../components/ErrorPage/ErrorPage';
import RegistrationConfirmation from '../pages/Auth/RegistrationConfirmation';
import ReviewRequest from '../pages/ReviewRequest/ReviewRequest';
import InActiveUser from '../pages/Auth/InActiveUser';
import { ClientTypes, UserStatusTypes } from '../redux/auth/AuthModels';
import { Roles } from '../pages/Account/Members/MemberRoleMappings';

/** Determine If Route is Authenticated and Completed Profile*/
const ProtectedRoute = ({ children }: any) => {
  const location = useLocation();
  // For better performance and Memoization created separate selectors
  const userDetailsStatus = useSelector((state: RootState) => state.authData.userDetailsStatus);
  const userDetails = useSelector((state: RootState) => state.authData.userDetails);
  const clientType = useSelector((state: RootState) => state.authData.clientType);
  const userDetailsError = useSelector((state: RootState) => state.authData.userDetailsError);
  // For better performance and Memoization created separate selectors
  const userBpMappingStatus = useSelector((state: RootState) => state.authData.userBpMappingStatus);
  const userBpMappingError = useSelector((state: RootState) => state.authData.userBpMappingError);

  const tblBpUserMapping = userDetails?.tblBpUserMapping;
  const tblUser = tblBpUserMapping?.tblUser;
  const isAgency = clientType === ClientTypes.Agency;
  const isAccountOwner = tblBpUserMapping?.tblUserRole?.name === Roles.ACCOUNT_OWNER;

  const isUserDetailsFetching =
    userDetailsStatus === 'loading' ||
    userBpMappingStatus === 'loading' ||
    userBpMappingStatus === 'idle';

  const isAuthenticated = isUserAuthenticated();
  if (isAuthenticated) {
    if (isUserDetailsFetching) {
      return <PageLoader />;
    }
    if (userDetailsError || userBpMappingError) {
      return (
        <ErrorPage title='Something went wrong' message={userDetailsError || userBpMappingError} />
      );
    }
    if (tblUser?.userStatusId === 1) {
      return <RegistrationConfirmation />;
    }

    if (tblUser) {
      if (
        tblUser.tblUserStatus.name === 'NeedsReview' ||
        tblUser.tblUserStatus.name === 'Pending'
      ) {
        return <ReviewRequest isUnderReview={true} />;
      }
    } else {
      if (userDetails?.isWaitingForActivation) {
        // Invite user flow
        return <CompleteRegistration />;
      } else {
        // New user flow
        return <ReviewRequest isUnderReview={false} />;
      }
    }

    if (tblUser?.tblUserStatus?.name === UserStatusTypes.Inactive) {
      return <InActiveUser />;
    }
    // check access for invoice and order sharing page as it need special permissions to it
    if (location.pathname === PATHS.INVOICES_AND_ORDER_SHARING && (!isAgency || !isAccountOwner)) {
      return <Navigate to={PATHS.OVERVIEW} />;
    }
    return children;
  } else {
    return <Navigate to={PATHS.LOGIN} />;
  }
};

export default ProtectedRoute;
