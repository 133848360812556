import { Box, MenuItem, Button, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { ICustomer } from '../../redux/auth/AuthModels';
import SpectrumTextField from '../../components/Spectrum/TextField/SpectrumTextField';
import SpectrumSelectField from '../../components/Spectrum/Select/SpectrumSelect';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const customerUtils = {
  add: (customers: ICustomer[], newCustomer: ICustomer) => [...customers, newCustomer],
  edit: (customers: ICustomer[], id: number, updatedFields: any) =>
    customers.map((item) => (item.id === id ? { ...item, ...updatedFields } : item)),
  delete: (customers: ICustomer[], id: number) => customers.filter((item) => item.id !== id),
};

interface IMultiCustomers {
  customers: ICustomer[];
  onChange: (data: any) => void;
}

const MultiCustomers = ({ customers, onChange }: IMultiCustomers) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const handleAddCustomer = () => {
    onChange({
      target: {
        name: 'customers',
        value: customerUtils.add(customers, {
          id: new Date().getTime(),
          customerId: '',
          companyType: '',
        }),
      },
    });
  };
  const handleDeleteCustomer = (id: number) => {
    onChange({
      target: {
        name: 'customers',
        value: customerUtils.delete(customers, id),
      },
    });
  };
  const handleEdit = (id: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const value: any = event.target.value;
    onChange({
      target: {
        name: 'customers',
        value: customerUtils.edit(customers, id, { [name]: value }),
      },
    });
  };
  return (
    <Box>
      <Box display='flex' gap={12} flexDirection='column'>
        {customers.map((customer) => {
          return (
            <Box
              key={customer.id}
              display='flex'
              alignItems='center'
              gap={isMobile ? 4 : 15}
              position='relative'
              flexWrap={isMobile ? 'wrap' : 'nowrap'}
            >
              <Box width={isMobile ? '80%' : 'auto'}>
                <SpectrumSelectField
                  required
                  label='Select Advertiser / Agency?'
                  variant='outlined'
                  size='small'
                  name='companyType'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleEdit(customer.id, event)
                  }
                  value={customer.companyType}
                >
                  <MenuItem value='advertiser'>Advertiser</MenuItem>
                  <MenuItem value='agency'>Agency</MenuItem>
                </SpectrumSelectField>
              </Box>
              <Box flexGrow={isMobile ? 0 : 1} width={isMobile ? '80%' : 'auto'}>
                <SpectrumTextField
                  required
                  label='Customer ID'
                  variant='outlined'
                  size='small'
                  name='customerId'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleEdit(customer.id, event)
                  }
                  value={customer.customerId}
                />
              </Box>
              {customers.length > 1 && (
                <Box
                  position='absolute'
                  top={isMobile ? '20px' : '26px'}
                  right={isMobile ? '0' : '-70px'}
                >
                  <Button onClick={() => handleDeleteCustomer(customer.id)}>
                    <DeleteOutlineIcon />
                  </Button>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
      <Button onClick={handleAddCustomer} sx={{ mt: 12 }}>
        <AddCircleIcon sx={{ mr: 2 }} /> Add Customer ID
      </Button>
    </Box>
  );
};
export default MultiCustomers;
