import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, MenuItem, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import SpectrumSelectField from '../../../components/Spectrum/Select/SpectrumSelect';
import { RootState, StoreDispatch } from '../../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { changeRole, fetchRoles } from '../../../redux/account/AccountSlice';
import { ComponentOverlayLoader } from '../../../components/Loaders/Loader';
import { Actions } from '../Members/MemberRoleMappings';
import { IUserAccountDetail } from '../../../redux/account/AccountModels';

interface IChangeUserRoleProps {
  onClose: ({ type }: any) => void;
  member: IUserAccountDetail;
}

const ChangeUserRole = ({ member, onClose }: IChangeUserRoleProps) => {
  const dispatch: StoreDispatch = useDispatch();
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;

  const [stage, setStage] = useState(1);

  const { roles, rolesError, rolesStatus } = useSelector((state: RootState) => state.accountData);

  const [newRole, setNewRole] = useState<any>('');

  const [changeRoleDetails, setChangeRoleDetails] = useState({ status: '', error: '' });

  const handleConfirm = () => {
    setChangeRoleDetails({ ...changeRoleDetails, status: 'loading' });
    changeRole({ changeUserSSOId: member.userSSOId, changeRoleTo: newRole })
      .then(() => {
        setChangeRoleDetails({ ...changeRoleDetails, status: 'succeeded' });
        setStage(2);
      })
      .catch((error) => {
        setChangeRoleDetails({ ...changeRoleDetails, status: 'failed', error: error?.error });
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewRole(event.target.value);
  };

  const handleClose = () => {
    onClose({ type: Actions.CHANGE_USER_ROLE });
  };

  useEffect(() => {
    if (!roles.length) {
      dispatch(fetchRoles({ includeAccountOwner: false }));
    }
  }, []);

  const isLoading = rolesStatus === 'loading' || changeRoleDetails?.status === 'loading';
  const newRoleDetails = roles.find((role) => role?.id === newRole);

  return (
    <div>
      {stage === 1 && (
        <>
          <Typography variant='h2' mb={5}>
            Change User Role
          </Typography>
          {isLoading && <ComponentOverlayLoader />}
          <Typography variant='body1' mb={10} color={grey[500]}>
            All team members will be able to view and pay invoices, Managers can also manage team
            members access to individual accounts
          </Typography>
          <SpectrumSelectField
            required
            label='Role'
            variant='outlined'
            size='medium'
            name='newOwner'
            onChange={handleChange}
            value={newRole}
            displayEmpty
          >
            <MenuItem value='' disabled>
              Select
            </MenuItem>
            {roles.map((role) => (
              <MenuItem key={role?.id} value={role?.id}>
                {role?.name}
              </MenuItem>
            ))}
          </SpectrumSelectField>
          {(rolesError || changeRoleDetails?.error) && (
            <Alert severity='error' variant='outlined' sx={{ mt: 10, mb: 10 }}>
              <Typography variant='paragraph2' component='span' color='error'>
                {rolesError || changeRoleDetails?.error}
              </Typography>
            </Alert>
          )}
          <Box sx={{ mt: 15, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={handleConfirm}
              variant='contained'
              size='large'
              fullWidth
              disabled={changeRoleDetails?.status === 'loading'}
            >
              Confirm
            </Button>
          </Box>
        </>
      )}
      {stage === 2 && (
        <>
          <Typography variant='h2' mb={5}>
            Role Changed
          </Typography>
          <Typography variant='body1' mb={10} color={grey[500]}>
            {member?.userName} role has successfully been changed to {newRoleDetails?.name}
          </Typography>
          <Box sx={{ mt: 15, display: 'flex', justifyContent: 'flex-start' }}>
            <Button onClick={handleClose} variant='contained' size='large'>
              Ok
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};
export default ChangeUserRole;
