import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import { Box, MenuItem, Typography, useMediaQuery, useTheme } from '@mui/material';
import { setSelectedBpMappingId } from '../../redux/auth/AuthSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATHS } from '../../routes/Routes';
import { StyledMenuPopover } from '../UserMenu/UserMenuStyles';
import { StyledContainer } from './BpMappingMenuStyles';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { cancelAllRequests } from '../../services/ApiService';

const BpMappingMenu = () => {
  const dispatch: StoreDispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { userDetails, userBpMappingDetails, selectedBpMappingId } = useSelector(
    (state: RootState) => state.authData,
  );

  const handleSelect = (bpId: number) => {
    cancelAllRequests();
    dispatch(setSelectedBpMappingId(bpId));
    redirectBasedOnPath();
  };

  const redirectBasedOnPath = () => {
    switch (location.pathname) {
      case PATHS.INVOICES_AND_ORDERS:
      case PATHS.INVOICES_AND_ORDER_SHARING:
      case PATHS.MY_TEAM:
      case PATHS.HELP:
        break;
      default:
        navigate(PATHS.OVERVIEW);
    }
  };

  const selectedBpMappingDetails = (userBpMappingDetails || []).find(
    (bpMapping) => bpMapping.bpId === selectedBpMappingId,
  );

  return (
    <StyledContainer
      display='flex'
      justifyContent='space-between'
      sx={{ flexDirection: isMobile ? 'column' : 'row' }}
      gap={isMobile ? theme.spacing(8) : theme.spacing(4)}
    >
      <Box display='flex' gap={theme.spacing(4)}>
        <Typography variant='h4'>Welcome back,</Typography>
        <Typography
          textTransform='capitalize'
          variant='h4'
          component='span'
          color={theme.palette.secondary.main}
        >
          {userDetails?.tblBpUserMapping?.tblUser?.firstName}
        </Typography>
      </Box>
      <div>
        <Box onClick={handleClick} sx={{ cursor: 'pointer' }}>
          <Typography variant='h6' display='flex' alignItems='center'>
            Account:
            <Typography variant='h6' color='primary' fontWeight='bold' sx={{ pl: 2, pr: 2 }}>
              {selectedBpMappingDetails?.bpName}
            </Typography>
            <Typography variant='h6' color='primary'>
              #{selectedBpMappingDetails?.bpSapId}
            </Typography>
            <ExpandCircleDownIcon fontSize='small' className={open ? 'icon open' : 'icon'} />
          </Typography>
        </Box>
        <StyledMenuPopover
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {(userBpMappingDetails || []).map((bpMapping) => {
            return (
              <MenuItem
                key={bpMapping.bpId}
                value={bpMapping.bpId}
                onClick={() => handleSelect(bpMapping.bpId)}
                selected={selectedBpMappingDetails?.bpId === bpMapping.bpId}
              >
                <Typography variant='paragraph2' sx={{ fontSize: 14 }} display='flex'>
                  <strong>{bpMapping?.bpName}</strong>{' '}
                  <Box ml={3}>#{bpMapping?.bpSapId}</Box>
                </Typography>
              </MenuItem>
            );
          })}
        </StyledMenuPopover>
      </div>
    </StyledContainer>
  );
};

export default BpMappingMenu;
