import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, MenuItem, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import SpectrumSelectField from '../../../components/Spectrum/Select/SpectrumSelect';
import { RootState, StoreDispatch } from '../../../redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAccountAllUsers,
  transferAccountOwnership,
} from '../../../redux/account/AccountSlice';
import { ComponentOverlayLoader } from '../../../components/Loaders/Loader';
import { Actions, Roles } from '../Members/MemberRoleMappings';

interface ITransferOwnership {
  onClose: ({ type }: any) => void;
}

const TransferOwnership = ({ onClose }: ITransferOwnership) => {
  const dispatch: StoreDispatch = useDispatch();
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const black: ColorPartial = theme.palette.black as ColorPartial;

  const [stage, setStage] = useState(1);

  const { accountAllUsers, accountAllUsersError, accountAllUsersStatus } = useSelector(
    (state: RootState) => state.accountData,
  );

  const usersWithManagerRole = accountAllUsers.filter((user) => user.userRole === Roles.MANAGER);

  const [newOwner, setNewOwner] = useState('');

  const [transferDetails, setTransferDetails] = useState({ status: '', error: '' });

  const handleConfirm = () => {
    setTransferDetails({ ...transferDetails, status: 'loading' });
    transferAccountOwnership({ transferToUserSSOId: newOwner })
      .then(() => {
        setTransferDetails({ ...transferDetails, status: 'succeeded' });
        setStage(2);
      })
      .catch((error) => {
        setTransferDetails({ ...transferDetails, status: 'failed', error: error?.error });
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewOwner(event.target.value);
  };

  const handleClose = () => {
    onClose({ type: Actions.TRANSFER_OWNERSHIP });
  };

  useEffect(() => {
    dispatch(fetchAccountAllUsers());
  }, []);

  const isLoading = accountAllUsersStatus === 'loading' || transferDetails?.status === 'loading';

  const newOwnerDetails = usersWithManagerRole.find((user) => user?.userSSOId === newOwner);

  return (
    <div>
      {stage === 1 && (
        <>
          <Typography variant='h2' mb={5}>
            Transfer Ownership
          </Typography>
          {isLoading && <ComponentOverlayLoader />}
          <Typography variant='body1' mb={10} color={grey[500]}>
            Transfer account ownership to another manager of your team
          </Typography>
          <Alert severity='warning' sx={{ mb: 10, mt: 10 }}>
            <Typography variant='paragraph2' component='span' color={black[500]}>
              Ownership transfers cannot be undone.
            </Typography>
          </Alert>
          <SpectrumSelectField
            required
            label='Member to transfer ownership to'
            variant='outlined'
            size='medium'
            name='newOwner'
            onChange={handleChange}
            value={newOwner}
            displayEmpty
          >
            <MenuItem value='' disabled>
              Select
            </MenuItem>
            {usersWithManagerRole.map((item) => (
              <MenuItem key={item?.userSSOId} value={item?.userSSOId}>
                {item?.userName}
              </MenuItem>
            ))}
          </SpectrumSelectField>
          {(accountAllUsersError || transferDetails?.error) && (
            <Alert severity='error' variant='outlined' sx={{ mt: 10, mb: 10 }}>
              <Typography variant='paragraph2' component='span' color='error'>
                {accountAllUsersError || transferDetails?.error}
              </Typography>
            </Alert>
          )}
          <Box sx={{ mt: 15, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={handleConfirm}
              variant='contained'
              size='large'
              fullWidth
              disabled={transferDetails?.status === 'loading'}
            >
              Confirm
            </Button>
          </Box>
        </>
      )}
      {stage === 2 && (
        <>
          <Typography variant='h2' mb={5}>
            Ownership Transferred
          </Typography>
          <Typography variant='body1' mb={10} color={grey[500]}>
            Ownership has successfully been transferred to {newOwnerDetails?.userName}
          </Typography>
          <Box sx={{ mt: 15, display: 'flex', justifyContent: 'flex-start' }}>
            <Button onClick={handleClose} variant='contained' size='large'>
              Ok
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};
export default TransferOwnership;
