export interface IUserDetails {
  tblBpUserMapping: TblBpUserMapping;
  isWaitingForActivation: boolean;
  userRegisteredAlready: boolean;
}

export interface TblBpUserMapping {
  id: number;
  userId: number;
  tblUser: TblUser;
  userRoleId: number;
  tblUserRole: TblUserRole;
  agencyId: number;
  tblAgency: TblAgency;
  advertiserId: any;
  tblAdvertiser: any;
  reportsTo: number;
  isActive: boolean;
}

export interface TblUser {
  id: number;
  cognitoUserId: string;
  userName: string;
  acceptedTermsAndConditions: boolean;
  acceptedTermsAndConditionsDateTime: string;
  firstName: string;
  lastName: string;
  userStatusId: number;
  tblUserStatus: TblUserStatus;
  userNotes: any;
  userEmail: string;
}

export interface TblUserStatus {
  id: number;
  name: string;
}

export interface TblUserRole {
  id: number;
  name: string;
}

export interface TblAgency {
  id: number;
  agencyId: string;
  agencyName: string;
  notes: string;
}

export interface IUserBpMappingDetails {
  bpId: number;
  ssoId: string;
  bpSapId: string;
  bpName: string;
  bpType: string;
}

export interface TblUser {
  id: number;
  userId: string;
  acceptedTermsAndConditions: boolean;
  acceptedTermsAndConditionsDateTime: string;
  firstName: string;
  lastName: string;
}

export interface IFetchTokenUsingCodePayload {
  grant_type: string;
  code: string;
  redirect_uri: string;
  client_id: string;
  scope: string;
}
export interface IFetchAccessTokenUsingRefreshToken {
  grant_type: string;
  client_id: string;
  refresh_token: string;
}

export interface ICustomer {
  id: number;
  customerId: string;
  companyType: string;
}

export interface INewUserRegistrationDetails {
  firstName: string;
  lastName: string;
  companyName: string;
  customers: ICustomer[];
  accountExecutive: string;
  recentInvoiceNumber: string;
  worksWithOtherAgencies?: boolean;
  isTermsAndConditionsAccepted: boolean;
}

export interface INewUserRegistrationDetailsPayload {
  firstName: string;
  lastName: string;
  companyName: string;
  agencyCustomerIds: string;
  advertiserCustomerIds: string;
  accountExecutive: string;
  recentInvoiceNumber: string;
  worksWithOtherAgencies?: boolean;
  isTermsAndConditionsAccepted: boolean;
}

export interface IPendingUserRegistrationDetails {
  firstName: string;
  lastName: string;
  isTermsAndConditionsAccepted: boolean;
}

export interface ITokenResponse {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

export enum ClientTypes {
  Agency = 'Agency',
  Advertiser = 'Advertiser',
}
export enum UserStatusTypes {
  Inactive = 'Inactive',
  Active = 'Active',
}

export interface IAuthState {
  userBpMappingDetails: IUserBpMappingDetails[] | null;
  userBpMappingStatus: string;
  userBpMappingError: any;
  selectedBpMappingId: number | undefined;
  userDetails: IUserDetails | null;
  clientType: ClientTypes | null;
  clientId: string;
  clientName: string;
  userDetailsStatus: string;
  userDetailsError: any;
  accessTokenStatus: string;
  error: any;
  signupPendingUserError: any;
  signupPendingUserStatus: string;
  signupNewUserStatus: string;
  signupNewUserError: any;
}
