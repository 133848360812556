import React, { useState } from 'react';
import { Alert, Box, Button, Typography, useTheme } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/Store';
import { inviteUser } from '../../../redux/account/AccountSlice';
import { ComponentOverlayLoader } from '../../../components/Loaders/Loader';
import {
  IAdvertiserSelection,
  IInviteUserDetails,
  IInviteUserPayload,
} from '../../../redux/account/AccountModels';
import AccountAccessSelection from '../AccountAccessSelection';

interface IAccountAccessStepProps {
  userDetails: IInviteUserDetails;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleNext: () => void;
  handlePrevious: () => void;
}

const AccountAccessStep = ({
  userDetails,
  handleChange,
  handleNext,
  handlePrevious,
}: IAccountAccessStepProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const { advertisers, advertisersError, advertisersStatus } = useSelector(
    (state: RootState) => state.accountData,
  );

  const [inviteUserDetails, setInviteUserDetails] = useState({ status: '', error: '' });
  const selectedAdvertisers = userDetails?.selectedAdvertisers || [];

  const isLoading = advertisersStatus === 'loading' || inviteUserDetails.status === 'loading';

  const isError = advertisersError || inviteUserDetails?.error;

  const handleSelection = (data: IAdvertiserSelection[]) => {
    const value: any = {
      target: { name: 'selectedAdvertisers', value: data },
    };
    handleChange(value);
  };

  const modAdvertisers: IAdvertiserSelection[] = advertisers.map((advertiser) => ({
    ...advertiser,
    isChecked:
      selectedAdvertisers.find((selectedAdvertiser) => selectedAdvertiser.id === advertiser.id)
        ?.isChecked || false,
  }));

  const handleSendInvitation = () => {
    const payload: IInviteUserPayload = {
      email: userDetails?.email,
      roleId: userDetails?.role,
      advertisers: selectedAdvertisers.map((selectedAdvertiser) => ({
        advertiserId: selectedAdvertiser?.id,
        isChecked: selectedAdvertiser?.isChecked,
      })),
    };
    setInviteUserDetails({ ...inviteUserDetails, status: 'loading', error: '' });
    inviteUser(payload)
      .then(() => {
        setInviteUserDetails({ ...inviteUserDetails, status: 'succeeded' });
        handleNext();
      })
      .catch((error) => {
        setInviteUserDetails({
          ...inviteUserDetails,
          status: 'failed',
          error: error?.error || 'Failed to invite user',
        });
      });
  };

  return (
    <div>
      <Typography variant='h2' mb={5}>
        Account Access
      </Typography>
      <Typography variant='body1' mb={10} color={grey[500]}>
        Determine which account this team member has access to
      </Typography>
      {isLoading && <ComponentOverlayLoader />}
      <AccountAccessSelection data={modAdvertisers} onSelect={handleSelection} />
      {isError && (
        <Alert severity='error' variant='outlined' sx={{ mt: 10, mb: 10 }}>
          <Typography variant='paragraph2' component='span' color='error'>
            {isError}
          </Typography>
        </Alert>
      )}
      <Box sx={{ display: 'flex', mt: 15 }}>
        <Button sx={{ mr: 5 }} onClick={handlePrevious} variant='outlined' size='large' fullWidth>
          Back
        </Button>
        <Button
          sx={{ ml: 5 }}
          onClick={handleSendInvitation}
          variant='contained'
          size='large'
          fullWidth
          disabled={inviteUserDetails?.status === 'loading'}
        >
          Send Invitation
        </Button>
      </Box>
    </div>
  );
};
export default AccountAccessStep;
