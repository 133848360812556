import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import SpectrumTextField from '../../components/Spectrum/TextField/SpectrumTextField';
import { INewUserRegistrationDetails } from '../../redux/auth/AuthModels';
import MultiCustomers from './MultiCustomers';

interface IYourInformation {
  formData: INewUserRegistrationDetails;
  handleChange: (x: React.ChangeEvent<HTMLInputElement>) => void;
}

const YourInformation = ({ formData, handleChange }: IYourInformation) => {
  const theme = useTheme();

  return (
    <>
      <Box>
        <Typography variant='h4' marginBottom={theme.spacing(8)}>
          Your Information
        </Typography>
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        gap={theme.spacing(12)}
        marginY={theme.spacing(12)}
      >
        <SpectrumTextField
          required
          label='First Name'
          variant='outlined'
          size='small'
          name='firstName'
          onChange={handleChange}
          value={formData.firstName}
        />
        <SpectrumTextField
          required
          label='Last Name'
          variant='outlined'
          size='small'
          name='lastName'
          onChange={handleChange}
          value={formData.lastName}
        />
        <SpectrumTextField
          required
          label='Your Company'
          variant='outlined'
          size='small'
          name='companyName'
          onChange={handleChange}
          value={formData.companyName}
        />
        <MultiCustomers customers={formData.customers} onChange={handleChange}/>
        <SpectrumTextField
          required
          label='Account Executive'
          variant='outlined'
          size='small'
          name='accountExecutive'
          onChange={handleChange}
          value={formData.accountExecutive}
        />
        <SpectrumTextField
          label='Recent Invoice Number'
          variant='outlined'
          size='small'
          name='recentInvoiceNumber'
          onChange={handleChange}
          value={formData.recentInvoiceNumber}
        />
      </Box>
    </>
  );
};

export default YourInformation;
