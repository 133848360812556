import React from 'react';
import { IAdvertiserSelection } from '../../redux/account/AccountModels';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

interface IAccountAccessSelection {
  data: IAdvertiserSelection[];
  onSelect: (data: IAdvertiserSelection[]) => void;
}

const AccountAccessSelection = ({ data = [], onSelect }: IAccountAccessSelection) => {
  const handleItemSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    advertiser: IAdvertiserSelection,
  ) => {
    advertiser.isChecked = event.target.checked;
    onSelect(data);
  };

  const selectedItems = data.filter((advertiser) => advertiser?.isChecked);
  const isAllChecked = selectedItems?.length === data?.length;
  const isIndeterminate = !isAllChecked && selectedItems.length > 0;

  const handleSelectAll = () => {
    if (isAllChecked) {
      onSelect(data.map((advertiser) => ({ ...advertiser, isChecked: false })));
    } else {
      onSelect(data.map((advertiser) => ({ ...advertiser, isChecked: true })));
    }
  };

  return (
    <>
      {data?.length > 0 ? (
        <Box>
          <FormControlLabel
            label='Select All'
            control={
              <Checkbox
                checked={isAllChecked}
                indeterminate={isIndeterminate}
                onChange={handleSelectAll}
              />
            }
          />
          <Box sx={{ ml: 20, maxHeight: 350, overflow: 'auto' }}>
            {data.map((advertiser: IAdvertiserSelection) => {
              return (
                <Box key={advertiser?.advertiserId}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={advertiser?.isChecked}
                        onChange={(event) => handleItemSelect(event, advertiser)}
                      />
                    }
                    label={`${advertiser?.advertiserName} (#${advertiser?.advertiserId})`}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : (
        <Box sx={{ height: 40 }}></Box>
      )}
    </>
  );
};
export default AccountAccessSelection;
