import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../services/ApiService';
import { transactionApiEndPoints } from '../../constants/apiConstants';
import { IFilterPayload, ITransactionHistoryData, ITransactionState } from './TransactionModel';

const initialState: ITransactionState = {
  transaction: {} as ITransactionHistoryData,
  error: '',
  transactionHistoryStatus: 'idle',
  filters: {
    start: 0,
    length: 10,
    sortColumn: '',
    sortDirection: '',
    searchValue: '',
    period: '',
  },
};

export const fetchTransactions = createAsyncThunk(
  'transactionHistory/GetTransactions',
  async (payload: IFilterPayload) => {
    const { data } = await ApiService.postData(
      transactionApiEndPoints.getTransactionHistoryURL,
      {},
      payload,
    );
    return data;
  },
);

export const TransactionHistorySlice = createSlice({
  name: 'transactionHistory',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      state.filters = action?.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.error = '';
        state.transactionHistoryStatus = 'loading';
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.transactionHistoryStatus = 'succeeded';
        state.transaction = action.payload.data;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.transactionHistoryStatus = 'failed';
        state.error = action?.error?.message || 'Failed to get transaction history';
      });
  },
});

export const { updateFilters } = TransactionHistorySlice.actions;
export default TransactionHistorySlice.reducer;
