import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../../services/ApiService';
import { autoPayApiEndPoints, paymentApiEndPoints } from '../../constants/apiConstants';
import {
  IAPIResponseError,
  INewPaymentBankDetailsPayload,
  INewPaymentCardDetailsPayload,
  IPaymentMethodState,
  IScheduleAutoPayPayload,
} from './AutoPayModel';

const initialState: IPaymentMethodState = {
  paymentMethods: [],
  autoPayError: null,
  autoPayStatus: 'idle',
  autoPayConfirmationStatus: 'idle',
  defaultStoredPaymentStatus: 'idle',
  removeStoredPaymentStatus: 'idle',
  addPaymentMethodStatus: 'idle',
  addPaymentMethodError: null,
};

export const fetchStoredPayments = createAsyncThunk('payment/getStoredPayment', async () => {
  const response = await ApiService.getData(paymentApiEndPoints.storedPayments, {});
  return response.data;
});

export const setDefaultPayment = createAsyncThunk(
  'payment/setDefaultPayment',
  async (paymentTokenId: number) => {
    const response = await ApiService.postData(paymentApiEndPoints.setDefaultPayment, {
      paymentTokenId,
    });
    return response.data;
  },
);

export const removeStoredPayment = createAsyncThunk(
  'payment/removeStoredPayment',
  async (paymentTokenId: string) => {
    const response = await ApiService.postData(paymentApiEndPoints.removeStoredPayment, {
      paymentTokenId,
    });
    return response.data;
  },
);

export const enableAutoPay = createAsyncThunk(
  'AutoPay/EnableAutoPay',
  async (payload: IScheduleAutoPayPayload, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(autoPayApiEndPoints.enableAutoPay, null, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const addNewCardPayment = createAsyncThunk(
  'AutoPay/AddNewCardPayment',
  async (payload: INewPaymentCardDetailsPayload, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(
        autoPayApiEndPoints.addCreditCardPayment,
        null,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
export const addNewBankAccountPayment = createAsyncThunk(
  'AutoPay/AddNewBankAccount',
  async (payload: INewPaymentBankDetailsPayload, { rejectWithValue }) => {
    try {
      const response = await ApiService.postData(
        autoPayApiEndPoints.addBankAccountPayment,
        null,
        payload,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const AutoPaySlice = createSlice({
  name: 'autoPayData',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchStoredPayments.pending, (state) => {
        state.autoPayError = '';
        state.autoPayStatus = 'loading';
      })
      .addCase(fetchStoredPayments.fulfilled, (state, action) => {
        state.autoPayStatus = 'succeeded';
        state.paymentMethods = action?.payload?.data;
        state.autoPayError = '';
      })
      .addCase(fetchStoredPayments.rejected, (state, action) => {
        state.autoPayStatus = 'failed';
        state.autoPayError = action?.error?.message || 'Failed to get payment methods';
      })
      .addCase(enableAutoPay.pending, (state) => {
        state.autoPayConfirmationStatus = 'loading';
      })
      .addCase(enableAutoPay.fulfilled, (state, action) => {
        state.autoPayConfirmationStatus = 'succeeded';
      })
      .addCase(enableAutoPay.rejected, (state, action) => {
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        state.autoPayConfirmationStatus = 'failed';
        state.autoPayError =
          response?.error || response?.title || 'Failed to schedule auto payment';
      })
      .addCase(removeStoredPayment.pending, (state) => {
        state.removeStoredPaymentStatus = 'loading';
      })
      .addCase(removeStoredPayment.fulfilled, (state, action) => {
        state.paymentMethods =
          state.paymentMethods?.filter(({ spmId }) => spmId !== action.payload.data.id) || [];
        state.removeStoredPaymentStatus = 'succeeded';
      })
      .addCase(removeStoredPayment.rejected, (state) => {
        state.removeStoredPaymentStatus = 'failed';
      })
      .addCase(setDefaultPayment.pending, (state) => {
        state.defaultStoredPaymentStatus = 'loading';
      })
      .addCase(setDefaultPayment.fulfilled, (state, action) => {
        state.paymentMethods = state.paymentMethods?.length
          ? state.paymentMethods.map((paymentMethod) =>
              paymentMethod.spmId === action.payload.data.id
                ? {
                    ...paymentMethod,
                    isDefault: action.payload.data.isDefault,
                  }
                : { ...paymentMethod, isDefault: false },
            )
          : [];
        state.defaultStoredPaymentStatus = 'succeeded';
      })
      .addCase(setDefaultPayment.rejected, (state) => {
        state.defaultStoredPaymentStatus = 'failed';
      })
      .addCase(addNewCardPayment.pending, (state) => {
        state.addPaymentMethodStatus = 'loading';
        state.addPaymentMethodError = '';
      })
      .addCase(addNewCardPayment.fulfilled, (state, action) => {
        state.addPaymentMethodStatus = 'succeeded';
        state.addPaymentMethodError = '';
      })
      .addCase(addNewCardPayment.rejected, (state, action) => {
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        state.addPaymentMethodStatus = 'failed';
        state.addPaymentMethodError =
          response?.error ||
          response?.title ||
          'Failed to add payment method. please check Card details and try again';
      })
      .addCase(addNewBankAccountPayment.pending, (state) => {
        state.addPaymentMethodStatus = 'loading';
        state.addPaymentMethodError = '';
      })
      .addCase(addNewBankAccountPayment.fulfilled, (state, action) => {
        state.addPaymentMethodStatus = 'succeeded';
        state.addPaymentMethodError = '';
      })
      .addCase(addNewBankAccountPayment.rejected, (state, action) => {
        const response: IAPIResponseError = action?.payload as IAPIResponseError;
        state.addPaymentMethodStatus = 'failed';
        state.addPaymentMethodError =
          response?.error ||
          response?.title ||
          'Failed to add payment method. please check Bank details and try again';
      });
  },
  reducers: {
    resetAutoPayStore: (state) => {
      state.autoPayError = null;
      state.autoPayStatus = 'idle';
      state.autoPayConfirmationStatus = 'idle';
    },
    resetAddPaymentStore: (state) => {
      state.addPaymentMethodError = null;
      state.addPaymentMethodStatus = 'idle';
    },
    resetRemovePaymentStore: (state) => {
      state.removeStoredPaymentStatus = 'idle';
    },
  },
});

export const { resetAutoPayStore, resetAddPaymentStore, resetRemovePaymentStore } =
  AutoPaySlice.actions;
export default AutoPaySlice.reducer;
