import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Divider, Typography, useTheme } from '@mui/material';

import YourInformation from './YourInformation';
import TermsAndConditions from './TermsAndConditions';
import IntroComponent from './IntroComponent';
import HelpText from './HelpText';
import { signupNewUser } from '../../redux/auth/AuthSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import {
  ICustomer,
  INewUserRegistrationDetails,
  INewUserRegistrationDetailsPayload,
} from '../../redux/auth/AuthModels';

const ReviewRequestSteps = () => {
  const theme = useTheme();
  const dispatch: StoreDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const { signupNewUserStatus, signupNewUserError } = useSelector(
    (state: RootState) => state.authData,
  );

  const [formData, setFormData] = useState<INewUserRegistrationDetails>({
    firstName: '',
    lastName: '',
    customers: [
      {
        id: new Date().getTime(),
        customerId: '',
        companyType: '',
      },
    ],
    companyName: '',
    accountExecutive: '',
    recentInvoiceNumber: '',
    isTermsAndConditionsAccepted: false,
  });

  const requiredFields: Array<keyof INewUserRegistrationDetails> = [
    'firstName',
    'lastName',
    'companyName',
    'customers',
    'accountExecutive',
    'isTermsAndConditionsAccepted',
  ];

  const isCustomersValid = (customers: ICustomer[]) => {
    return customers.every((customer) => customer.customerId && customer.companyType);
  };

  const isAllReqFieldsFilled = requiredFields.reduce((isValid, key) => {
    const checkValid = key === 'customers' ? isCustomersValid(formData[key]) : formData[key];
    return isValid && !!checkValid;
  }, true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    let value: any = event.target.value;
    if (name === 'worksWithOtherAgencies') {
      value = value === 'yes';
    } else if (name === 'isTermsAndConditionsAccepted') {
      value = event.target.checked;
    }
    setFormData((prv) => ({ ...prv, [name]: value }));
  };

  const handleSubmit = () => {
    const payload: INewUserRegistrationDetailsPayload = Object.keys(formData).reduce(
      (result: any, key) => {
        if (key !== 'customers') {
          result[key] = formData[key as keyof INewUserRegistrationDetails];
          return result;
        }
        const customersResult = formData[key].reduce(
          (prevResult: any, customer) => {
            if (customer.companyType === 'agency') {
              prevResult.agencyCustomerIds.push(customer.customerId);
            }
            if (customer.companyType === 'advertiser') {
              prevResult.advertiserCustomerIds.push(customer.customerId);
            }
            return prevResult;
          },
          { agencyCustomerIds: [], advertiserCustomerIds: [] },
        );
        result.advertiserCustomerIds = customersResult.advertiserCustomerIds.join(',');
        result.agencyCustomerIds = customersResult.agencyCustomerIds.join(',');
        return result;
      },
      {} as INewUserRegistrationDetailsPayload,
    );
    dispatch(signupNewUser(payload));
  };

  useEffect(() => {
    if (signupNewUserStatus === 'loading') {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [signupNewUserStatus]);

  return (
    <Box>
      <IntroComponent />
      <YourInformation formData={formData} handleChange={handleChange} />
      <TermsAndConditions formData={formData} handleChange={handleChange} />
      {signupNewUserError && (
        <Typography mt={10} mb={10} variant='paragraph2' color='error'>
          {signupNewUserError}
        </Typography>
      )}
      <Button
        variant='contained'
        size='large'
        onClick={handleSubmit}
        disabled={!isAllReqFieldsFilled || isLoading}
      >
        {isLoading ? (
          <CircularProgress
            size={28}
            style={{ color: theme.palette.common.white, marginRight: 10 }}
            className='loader'
          />
        ) : (
          'Submit for Review'
        )}
      </Button>
      <Box
        marginTop={theme.spacing(16)}
        display='flex'
        flexDirection='column'
        gap={theme.spacing(8)}
      >
        <Divider />
        <HelpText />
      </Box>
    </Box>
  );
};

export default ReviewRequestSteps;
