import React, { useEffect } from 'react';
import { ILink } from '../../types';

import Navbar from '../Nav/Nav';
import { PATHS } from '../../routes/Routes';
import Footer from '../Footer/Footer';

// Styles
import { StyledContainer } from './LayoutStyles';
import { isUserAuthenticated } from '../../utils/authUtils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import { fetchUserBpMappingDetails, fetchUserDetails } from '../../redux/auth/AuthSlice';
import HelpFooter from '../HelpFooter/HelpFooter';
import { fetchContactInfo } from '../../redux/help/HelpSlice';
import { ClientTypes, UserStatusTypes } from '../../redux/auth/AuthModels';
import { Roles } from '../../pages/Account/Members/MemberRoleMappings';
import BpMappingMenu from '../BpMappingMenu/BpMappingMenu';

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children, className = '' }: IProps) => {
  const dispatch: StoreDispatch = useDispatch();

  const {
    userDetails,
    accessTokenStatus,
    clientType,
    signupPendingUserStatus,
    signupNewUserStatus,
    selectedBpMappingId,
  } = useSelector((state: RootState) => state.authData);
  const isAgency = clientType === ClientTypes.Agency;
  const tblBpUserMapping = userDetails?.tblBpUserMapping;
  const isAccountOwner = tblBpUserMapping?.tblUserRole?.name === Roles.ACCOUNT_OWNER;

  const headerLinks: ILink[] = [
    {
      label: 'OVERVIEW',
      link: PATHS.OVERVIEW,
    },
    {
      label: 'INVOICE AND ORDER HISTORY',
      link: PATHS.INVOICES_AND_ORDERS,
    },
    {
      label: 'ACCOUNT SETTINGS',
      link: PATHS.ACCOUNT,
      children: [
        {
          label: 'My Team',
          link: PATHS.MY_TEAM,
        },
        ...(isAgency && isAccountOwner
          ? [
              {
                label: 'Invoice and Order Sharing',
                link: PATHS.INVOICES_AND_ORDER_SHARING,
              },
            ]
          : []),
      ],
    },
  ];

  const isAuthenticated = isUserAuthenticated();
  const tblUser = tblBpUserMapping?.tblUser;
  const isUserRegistered =
    tblUser?.userStatusId &&
    tblUser?.userStatusId !== 1 &&
    tblUser?.tblUserStatus?.name === UserStatusTypes.Active;

  useEffect(() => {
    if (isAuthenticated) {
      if (
        !userDetails ||
        Object.keys(userDetails).length === 0 ||
        signupPendingUserStatus === 'succeeded' ||
        signupNewUserStatus === 'succeeded'
      ) {
        dispatch(fetchUserDetails(true));
        dispatch(fetchUserBpMappingDetails());
      }
    }
  }, [accessTokenStatus, signupPendingUserStatus, signupNewUserStatus]);

  useEffect(() => {
    if (selectedBpMappingId) {
      dispatch(fetchUserDetails());
    }
  }, [selectedBpMappingId]);

  useEffect(() => {
    dispatch(fetchContactInfo());
  }, []);

  return (
    <>
      <Navbar
        links={headerLinks}
        isAuthenticated={!!isAuthenticated}
        isUserRegistered={!!isUserRegistered}
        user={userDetails}
        productTitle='Client Portal'
      />
      {isAuthenticated && isUserRegistered && <BpMappingMenu />}
      <StyledContainer className={className}>{children}</StyledContainer>
      {isAuthenticated && <HelpFooter />}
      <Footer></Footer>
    </>
  );
};

export default Layout;
