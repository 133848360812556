import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { RootState, StoreDispatch } from '../../redux/Store';
import { fetchUpcomingPayments } from '../../redux/payment/PaymentSlice';
import PageContentContainer from '../../components/PageContentContainer/PageContentContainer';
import StoredPaymentMethod from '../../components/StoredPaymentMethods/StoredPaymentMethod';
import UpcomingPayments from './UpcomingPayments';
import OpenInvoices from './OpenInvoices';
import OpenOrders from './OpenOrders';
import PaymentHistory from './PaymentHistory';
import { formatAmount } from '../../utils/common';
import { getDueInvoicesCount, getDueOrdersCount } from '../InvoicesAndOrders/InvoiceAndOrderUtils';
import { updateSelectedInvoices, updateSelectedOrders } from '../../redux/overview/OverviewSlice';

const Overview = () => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const dispatch: StoreDispatch = useDispatch();
  const { upcomingPayments } = useSelector((state: RootState) => state.paymentData);
  const { invoiceTotal, invoices, orders, managedOrders, managedInvoices } = useSelector(
    (state: RootState) => state.overview,
  );

  // For total balance we only using invoices total- this is requirement
  const totalBalance = formatAmount(invoiceTotal);

  const dueInvoicesCount = useMemo(
    () => getDueInvoicesCount([...invoices, ...managedInvoices]),
    [invoices, managedInvoices],
  );
  const dueOrdersCount = useMemo(
    () => getDueOrdersCount([...orders, ...managedOrders]),
    [orders, managedOrders],
  );

  useEffect(() => {
    dispatch(updateSelectedOrders([]));
    dispatch(updateSelectedInvoices([]));
    dispatch(fetchUpcomingPayments());
  }, []);

  return (
    <PageContentContainer noSpacing={isMobile}>
      <Box mt={theme.spacing(12)}>
        <Card
          sx={{
            backgroundColor: theme.palette.grey[100],
            marginTop: theme.spacing(6),
          }}
        >
          <CardContent
            sx={{
              padding: theme.spacing(12, isMobile ? 14 : 16),
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography variant='eyebrow'>Total Balance</Typography>
              <Typography variant='heading' mt={theme.spacing(3)}>
                {totalBalance}
              </Typography>
              <Box display='flex' flexWrap='wrap' gap={theme.spacing(3)}>
                <Typography
                  variant='h5'
                  color={theme.palette.grey[500]}
                  sx={{
                    fontWeight: theme.typography.fontWeightLight,
                  }}
                >
                  from
                </Typography>
                <Typography variant='h5' color={theme.palette.grey[500]} whiteSpace='nowrap'>
                  {dueInvoicesCount} invoices
                </Typography>
                <Typography
                  variant='h5'
                  color={theme.palette.grey[500]}
                  sx={{
                    fontWeight: theme.typography.fontWeightLight,
                  }}
                >
                  and
                </Typography>
                <Typography variant='h5' color={theme.palette.grey[500]} whiteSpace='nowrap'>
                  {dueOrdersCount} orders
                </Typography>
                <Typography
                  variant='h5'
                  color={theme.palette.grey[500]}
                  fontWeight={theme.typography.fontWeightLight}
                >
                  due soon
                </Typography>
              </Box>
            </Box>
            <Box
              ml={isMobile ? 0 : theme.spacing(16)}
              display='flex'
              flexDirection={isMobile ? 'column' : 'row'}
              sx={
                !isMobile
                  ? {
                      width: 257,
                    }
                  : undefined
              }
            >
              <Divider
                sx={{
                  ...(isMobile
                    ? { mb: theme.spacing(8), mt: theme.spacing(12) }
                    : { mr: theme.spacing(12) }),
                  border: `${theme.spacing(0.5)} solid ${grey[400]}`,
                }}
              />
              <UpcomingPayments payments={upcomingPayments} />
            </Box>
          </CardContent>
        </Card>
        <Box
          padding={theme.spacing(12)}
          sx={{
            border: `${theme.spacing(0.5)} solid ${grey[200]}`,
            width: '100%',
            mt: 8,
            mb: 8,
          }}
        >
          <OpenInvoices />
        </Box>
        <Box
          padding={theme.spacing(12)}
          sx={{
            border: `${theme.spacing(0.5)} solid ${grey[200]}`,
            width: '100%',
            mt: 8,
            mb: 8,
          }}
        >
          <OpenOrders />
        </Box>
        <Box
          padding={theme.spacing(12)}
          sx={{
            border: `${theme.spacing(0.5)} solid ${grey[200]}`,
            width: '100%',
            mt: 8,
            mb: 8,
          }}
        >
          <PaymentHistory />
        </Box>
        <Stack
          padding={theme.spacing(12)}
          gap={theme.spacing(8)}
          display='flex'
          sx={{
            border: `${theme.spacing(0.5)} solid ${grey[200]}`,
            width: '100%',
          }}
        >
          <Typography variant='h5'>Stored Payment Methods</Typography>
          <StoredPaymentMethod isSelectable={false} />
        </Stack>
      </Box>
    </PageContentContainer>
  );
};

export default Overview;
