import React, { Dispatch, Fragment, SetStateAction } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { formatAmount, formatDate, getLocalDateWithOffset, sameDay } from '../../../utils/common';

import { IPaymentMethod } from '../../../redux/autoPay/AutoPayModel';
import SelectedInvoicesAndOrders from '../SelectedInvoicesAndOrders/SelectedInvoicesAndOrders';
import {
  IMakePaymentInvoicePayload,
  IMakePaymentOrderPayload,
  InvoiceAndOrdersViewType,
  ISelectedInvoiceOrOrderItem,
  PaymentFlowType,
} from '../../../redux/overview/OverviewModels';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import {
  IMakePaymentInvoices,
  IMakePaymentOrders,
} from '../../../redux/InvoicesAndOrders/InvoicesAndOrderModels';
import {
  makeAdvertiserInvoiceImmediatePayment,
  makeAdvertiserInvoiceSchedulePayment,
  makeAdvertiserOrderImmediatePayment,
  makeAdvertiserOrderSchedulePayment,
  makeAgencyInvoiceImmediatePayment,
  makeAgencyInvoiceSchedulePayment,
  makeAgencyOrderImmediatePayment,
  makeAgencyOrderSchedulePayment,
} from '../../../redux/InvoicesAndOrders/InvoicesAndOrderSlice';
import { ClientTypes } from '../../../redux/auth/AuthModels';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { StyledBoxContainer } from './ReviewDetailStyles';
import ReviewPaymentMethod from '../../../components/ReviewPaymentMethod/ReviewPaymentMethod';

const ReviewPaymentAmount = ({
  amount,
  credits,
  moveToStepLabel,
  onMoveToStep,
}: {
  credits: number;
  amount: string;
  moveToStepLabel: string;
  onMoveToStep: (step: number) => void;
}) => {
  return (
    <>
      <Typography variant='h4'>Payment Amount</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', mt: 8 }}>
        <Typography variant='h6'>Your Payment:</Typography>
        <Typography variant='body1'>{amount}</Typography>
      </Box>
      {credits > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 8, mb: 8 }}>
          <Typography variant='h6'>Credits Applied:</Typography>
          <Typography variant='body1'>{formatAmount(credits)}</Typography>
        </Box>
      )}
      <Button
        variant='text'
        sx={{
          mt: 6,
          textDecoration: 'underline',
        }}
        onClick={() => onMoveToStep(1)}
      >
        {moveToStepLabel}
      </Button>
    </>
  );
};
const ReviewPaymentDate = ({
  date,
  moveToStepLabel,
  onMoveToStep,
}: {
  date: string;
  moveToStepLabel: string;
  onMoveToStep: (step: number) => void;
}) => {
  return (
    <>
      <Typography variant='h4'>Payment Date</Typography>
      <Typography variant='h6' mt={8}>
        {date && formatDate(date)}
      </Typography>
      <Button
        variant='text'
        sx={{
          mt: 6,
          textDecoration: 'underline',
        }}
        onClick={() => onMoveToStep(2)}
      >
        {moveToStepLabel}
      </Button>
    </>
  );
};

const ReviewDetails = ({
  payOn,
  totalAmount,
  selectedPaymentMethodDetail,
  setActiveStep,
  handlePrevious,
}: {
  payOn: any;
  totalAmount: number;
  selectedPaymentMethodDetail: IPaymentMethod;
  setActiveStep: Dispatch<SetStateAction<number>>;
  handlePrevious: () => void;
}) => {
  const theme = useTheme();
  const white: ColorPartial = theme.palette.white as ColorPartial;
  const dispatch: StoreDispatch = useDispatch();
  const { selectedPaymentFlowType } = useSelector((state: RootState) => state.paymentData);
  const { clientType } = useSelector((state: RootState) => state.authData);
  const isAgency = clientType === ClientTypes.Agency;
  const isInvoice = selectedPaymentFlowType === PaymentFlowType.Invoices;
  const {
    selectedInvoices,
    selectedOrders,
    selectedInvoicesCount,
    selectedOrdersCount,
    invoicePresignedURL,
  } = useSelector((state: RootState) => state.overview);
  const { makePaymentStatus } = useSelector((state: RootState) => state.invoiceAndOrdersData);

  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const formattedAmount = formatAmount(totalAmount);
  const label = isInvoice ? 'Invoices' : 'Orders';
  const count = isInvoice ? selectedInvoicesCount : selectedOrdersCount;

  const handleMakePayment = () => {
    if (isInvoice) {
      const selectedInvoiceNumbers: {
        managedInvoices: IMakePaymentInvoicePayload[];
        openInvoices: IMakePaymentInvoicePayload[];
      } = selectedInvoices.reduce(
        (
          invoiceNumbers: {
            managedInvoices: IMakePaymentInvoicePayload[];
            openInvoices: IMakePaymentInvoicePayload[];
          },
          invoice: ISelectedInvoiceOrOrderItem,
        ) => {
          let openInvoices = invoiceNumbers.openInvoices;
          let managedInvoices = invoiceNumbers.managedInvoices;
          const invoices = invoice.items.map((item) => {
            const isCustomAmount = !!(item.customAmount && item.customAmount !== item.amount);
            return {
              number: item.number,
              amount: isCustomAmount ? item.customAmount : item.amount,
              isCustomAmount: isCustomAmount,
            };
          });
          if (invoice.isManaged) {
            managedInvoices = managedInvoices.concat(invoices);
          } else {
            openInvoices = openInvoices.concat(invoices);
          }
          return { managedInvoices: managedInvoices, openInvoices: openInvoices };
        },
        { managedInvoices: [], openInvoices: [] },
      );
      const payload: IMakePaymentInvoices = {
        totalAmount: totalAmount,
        managedInvoices: selectedInvoiceNumbers.managedInvoices,
        openInvoices: selectedInvoiceNumbers.openInvoices,
        paymentDate: formatDate(payOn, 'yyyy-MM-dd'),
        spmId: selectedPaymentMethodDetail.spmId,
        presignedURL: invoicePresignedURL || '',
      };
      const isPayDateToday = sameDay(payOn, getLocalDateWithOffset());
      if (isAgency) {
        if (isPayDateToday) {
          dispatch(makeAgencyInvoiceImmediatePayment(payload));
        } else {
          dispatch(makeAgencyInvoiceSchedulePayment(payload));
        }
      } else {
        if (isPayDateToday) {
          dispatch(makeAdvertiserInvoiceImmediatePayment(payload));
        } else {
          dispatch(makeAdvertiserInvoiceSchedulePayment(payload));
        }
      }
    } else {
      const selectedOrderNumbers: {
        managedOrders: IMakePaymentOrderPayload[];
        openOrders: IMakePaymentOrderPayload[];
      } = selectedOrders.reduce(
        (
          orderNumbers: {
            managedOrders: IMakePaymentOrderPayload[];
            openOrders: IMakePaymentOrderPayload[];
          },
          order: ISelectedInvoiceOrOrderItem,
        ) => {
          let openOrders = orderNumbers.openOrders;
          let managedOrders = orderNumbers.managedOrders;
          const orders = order.items.map((item) => {
            const isCustomAmount = !!(item.customAmount && item.customAmount !== item.amount);
            return {
              number: item.number,
              orderDate: item.date,
              dueDate: item.dueDate,
              amount: isCustomAmount ? item.customAmount : item.amount,
              isCustomAmount: isCustomAmount,
            };
          });
          if (order.isManaged) {
            managedOrders = managedOrders.concat(orders);
          } else {
            openOrders = openOrders.concat(orders);
          }
          return { managedOrders: managedOrders, openOrders: openOrders };
        },
        { managedOrders: [], openOrders: [] },
      );
      const payload: IMakePaymentOrders = {
        totalAmount: totalAmount,
        managedOrders: selectedOrderNumbers.managedOrders,
        openOrders: selectedOrderNumbers.openOrders,
        paymentDate: formatDate(payOn, 'yyyy-MM-dd'),
        spmId: selectedPaymentMethodDetail.spmId,
      };
      const isPayDateToday = sameDay(payOn, getLocalDateWithOffset());
      if (isAgency) {
        if (isPayDateToday) {
          dispatch(makeAgencyOrderImmediatePayment(payload));
        } else {
          dispatch(makeAgencyOrderSchedulePayment(payload));
        }
      } else {
        if (isPayDateToday) {
          dispatch(makeAdvertiserOrderImmediatePayment(payload));
        } else {
          dispatch(makeAdvertiserOrderSchedulePayment(payload));
        }
      }
    }
  };

  return (
    <Fragment>
      <StyledBoxContainer className='header align-left'>
        <Typography variant='h4'>{label} to be paid</Typography>
        <Typography className='selected' variant='subtitle2' ml={8}>
          {count}
        </Typography>
      </StyledBoxContainer>
      <SelectedInvoicesAndOrders viewType={InvoiceAndOrdersViewType.REVIEW} />
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pl: 8, pr: 8, pb: 15 }}>
        <Typography variant='h5'>Total</Typography>
        <Typography variant='h5'>{formattedAmount}</Typography>
      </Box>
      <Divider />
      <Box>
        <ReviewPaymentAmount
          amount={formattedAmount}
          credits={0}
          onMoveToStep={setActiveStep}
          moveToStepLabel='Change Payment Amount'
        />
      </Box>
      <Divider />
      <Box>
        <ReviewPaymentDate
          date={payOn}
          onMoveToStep={setActiveStep}
          moveToStepLabel='Change Payment Date'
        />
      </Box>
      <Divider />
      <Box>
        <ReviewPaymentMethod
          selectedPaymentMethodDetail={selectedPaymentMethodDetail}
          moveToStepLabel='Change Payment Method'
          onMoveToStep={setActiveStep}
        />
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Button
          variant='outlined'
          size='large'
          startIcon={<ArrowBackOutlinedIcon />}
          sx={{
            width: 'fit-content',
            padding: theme.spacing(9, isMobile ? 12 : 16),
            margin: isMobile ? theme.spacing(0, 0, 10, 0) : theme.spacing(0),
          }}
          onClick={handlePrevious}
        >
          Previous
        </Button>
        <Button
          variant='contained'
          size='large'
          sx={{
            width: 'fit-content',
            padding: theme.spacing(9, isMobile ? 16 : 24),
          }}
          onClick={handleMakePayment}
          disabled={makePaymentStatus === 'loading'}
        >
          {makePaymentStatus === 'loading' ? (
            <>
              <CircularProgress
                sx={{
                  color: white[500],
                  width: '30px !important',
                  height: '30px !important',
                  mr: 10,
                }}
              />{' '}
              Continue
            </>
          ) : (
            'Continue'
          )}
        </Button>
      </Box>
    </Fragment>
  );
};

export default ReviewDetails;
