import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Typography, useTheme } from '@mui/material';
import { ComponentOverlayLoader } from '../../../components/Loaders/Loader';
import { ColorPartial } from '@mui/material/styles/createPalette';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../../redux/Store';
import {
  fetchAdvertisers,
  manageAccessAccount,
  setAdvertisers,
} from '../../../redux/account/AccountSlice';
import AccountAccessSelection from '../AccountAccessSelection';
import {
  IAdvertiser,
  IAdvertiserSelection,
  IManageAccountAccessPayload,
  IUserAccountDetail,
} from '../../../redux/account/AccountModels';
import { ClientTypes } from '../../../redux/auth/AuthModels';

interface IManageAccountAccessProps {
  onClose: ({ type }: any) => void;
  member: IUserAccountDetail;
}

const ManageAccountAccess = ({ member, onClose }: IManageAccountAccessProps) => {
  const dispatch: StoreDispatch = useDispatch();
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const [stage, setStage] = useState(1);

  const [selectedAdvertisers, setSelectedAdvertisers] = useState<IAdvertiserSelection[]>([]);
  const [accountAccessStatus, setAccountAccessStatus] = useState({ status: '', error: '' });

  const { accountInformation, advertisers, advertisersError, advertisersStatus } = useSelector(
    (state: RootState) => state.accountData,
  );
  const {
    clientType,
    clientId,
    userDetails: loginUserDetails,
  } = useSelector((state: RootState) => state.authData);

  const isAgency = clientType === ClientTypes.Agency;

  useEffect(() => {
    const selectedAdvertisers: IAdvertiserSelection[] = (member.advertisers || []).map(
      (advertiser) => ({ ...advertiser, isChecked: true }),
    );
    setSelectedAdvertisers(selectedAdvertisers);
  }, []);

  useEffect(() => {
    if (isAgency) {
      dispatch(fetchAdvertisers({ agencyId: accountInformation?.accountPkId }));
    } else {
      const advertiserDetails: IAdvertiser = {
        advertiserId: clientId,
        advertiserName: loginUserDetails?.tblBpUserMapping?.tblAdvertiser?.advertiserName || '',
        id: loginUserDetails?.tblBpUserMapping?.tblAdvertiser?.id || 0,
      };
      dispatch(setAdvertisers([advertiserDetails]));
    }
  }, []);

  const isLoading = advertisersStatus === 'loading' || accountAccessStatus.status === 'loading';
  const isError = advertisersError || accountAccessStatus?.error;

  const modAdvertisers: IAdvertiserSelection[] = advertisers.map((advertiser) => ({
    ...advertiser,
    isChecked:
      selectedAdvertisers.find((selectedAdvertiser) => selectedAdvertiser.id === advertiser.id)
        ?.isChecked || false,
  }));

  const handleSelection = (data: IAdvertiserSelection[]) => {
    setSelectedAdvertisers(data);
  };

  const handleConfirm = () => {
    const payload: IManageAccountAccessPayload = {
      userBpId: member?.userBpId,
      advertisers: selectedAdvertisers.map((selectedAdvertiser) => ({
        advertiserId: selectedAdvertiser?.id,
        isChecked: selectedAdvertiser?.isChecked,
      })),
    };
    setAccountAccessStatus({ ...accountAccessStatus, status: 'loading' });
    manageAccessAccount(payload)
      .then(() => {
        setAccountAccessStatus({ ...accountAccessStatus, status: 'succeeded' });
        setStage(2);
      })
      .catch((error) => {
        setAccountAccessStatus({
          ...accountAccessStatus,
          status: 'failed',
          error: error?.error || 'Failed to update account access',
        });
      });
  };

  return (
    <div>
      {stage === 1 && (
        <>
          <Typography variant='h2' mb={5}>
            Manage Account Access
          </Typography>
          {isLoading && <ComponentOverlayLoader />}
          <Typography variant='body1' mb={10} color={grey[500]}>
            Determine which accounts this team member has access to
          </Typography>
          <AccountAccessSelection data={modAdvertisers} onSelect={handleSelection} />
          {isError && (
            <Alert severity='error' variant='outlined' sx={{ mt: 10, mb: 10 }}>
              <Typography variant='paragraph2' component='span' color='error'>
                {isError}
              </Typography>
            </Alert>
          )}
          <Box sx={{ display: 'flex', mt: 15 }}>
            <Button sx={{ mr: 5 }} onClick={onClose} variant='outlined' size='large' fullWidth>
              Cancel
            </Button>
            <Button
              sx={{ ml: 5 }}
              onClick={handleConfirm}
              variant='contained'
              size='large'
              fullWidth
            >
              Confirm
            </Button>
          </Box>
        </>
      )}
      {stage === 2 && (
        <>
          <Typography variant='h2' mb={5}>
            Account access updated
          </Typography>
          <Typography variant='body1' mb={10} color={grey[500]}>
            Updated account access for {member?.userName}
          </Typography>
          <Box sx={{ mt: 15, display: 'flex', justifyContent: 'flex-start' }}>
            <Button onClick={onClose} variant='contained' size='large'>
              Ok
            </Button>
          </Box>
        </>
      )}
    </div>
  );
};
export default ManageAccountAccess;
