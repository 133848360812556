import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

export const StyledContainer = styled(Box)(({ theme }) => {
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: `${grey[500]}1A`,
    padding: theme.spacing(5, 8),
    '.icon': {
      margin: theme.spacing(0, 2),
      fill: theme.palette.primary.main,
      '&.open':{
        transform: 'rotate(180deg)',
        transition: 'all 0.3s',
      }
    },
  };
});
