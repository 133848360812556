import React from 'react';
import { Box, Chip, Divider, Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import Avatar from '../../../components/Avatar/Avatar';
import { ColorPartial } from '@mui/material/styles/createPalette';
import LinkedAdvertisers from './LinkedAdvertisers';
import MemberActions from '../MemberActions/MemberActions';
import { AccountStatus, GetAllowedActions, MemberColors, Roles } from './MemberRoleMappings';
import { IUserAccountDetail } from '../../../redux/account/AccountModels';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/Store';

interface IMember {
  data: IUserAccountDetail;
}

const Member = ({
  data,
  data: { userRole, advertisers, userEmail, userName, userInitial, userStatus },
}: IMember) => {
  const { userDetails } = useSelector((state: RootState) => state.authData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const primary: ColorPartial = theme.palette.primary as ColorPartial;
  const black: ColorPartial = theme.palette.black as ColorPartial;
  const isAccountOwner = userRole === Roles.ACCOUNT_OWNER;
  const loggedInRole: Roles = userDetails?.tblBpUserMapping?.tblUserRole?.name as Roles;
  const isPending = userStatus === AccountStatus.Pending;
  const allowedActions = isPending ? [] : GetAllowedActions(loggedInRole, userRole as Roles);
  const memberColor = isPending
    ? MemberColors[AccountStatus.Pending]
    : MemberColors[userRole as Roles];
  return (
    <Box sx={{ p: 10, boxShadow: 2, borderTop: `4px solid ${memberColor}` }}>
      <Grid container spacing={10}>
        <Grid item small={12} tablet={7.5}>
          <Box sx={{ display: 'flex' }}>
            <Avatar size='large' sx={{ backgroundColor: memberColor }}>
              {userInitial}
            </Avatar>
            <Box sx={{ ml: 5, flexGrow: 1 }}>
              <div>
                {isPending ? (
                  <Typography
                    component={Link}
                    variant='paragraph2'
                    color={grey[500]}
                    sx={{ display: 'inline-block', textDecorationColor: 'inherit', mb: 3 }}
                  >
                    {userEmail}
                  </Typography>
                ) : (
                  <Typography variant='h5'>{userName}</Typography>
                )}
                <Typography variant='eyebrow' sx={{ fontSize: 13, mb: 6 }} color={memberColor}>
                  {userRole}
                </Typography>
                {isPending ? (
                  <Chip
                    variant='filled'
                    color='primary'
                    sx={{ mb: 3, backgroundColor: primary[100], color: black[500] }}
                    label={userStatus}
                  />
                ) : (
                  <Typography
                    component={Link}
                    variant='paragraph2'
                    color={grey[500]}
                    sx={{ textDecorationColor: 'inherit' }}
                  >
                    {userEmail}
                  </Typography>
                )}
                {!isAccountOwner && advertisers?.length > 0 && (
                  <LinkedAdvertisers advertisers={advertisers || []} />
                )}
              </div>
            </Box>
          </Box>
        </Grid>
        {allowedActions?.length > 0 && (
          <>
            <Grid item small={12} tablet={1}>
              <Divider orientation={isMobile ? 'horizontal' : 'vertical'} />
            </Grid>
            <Grid item small={12} tablet={3.5}>
              <MemberActions allowedActions={allowedActions} member={data} />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
export default Member;
