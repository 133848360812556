import React, { SetStateAction, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControlLabel,
  Radio,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { ColorPartial } from '@mui/material/styles/createPalette';
import MethodDescriptionAsPerBrand from './MethodDescriptionAsPerBrand';
import { IPaymentMethod } from '../../redux/autoPay/AutoPayModel';
import ErrorPage from '../ErrorPage/ErrorPage';
import {
  fetchStoredPayments,
  removeStoredPayment,
  resetRemovePaymentStore,
} from '../../redux/autoPay/AutoPaySlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, StoreDispatch } from '../../redux/Store';
import AddNewPaymentMethod from '../../pages/MakePayment/AddNewPaymentMethod/AddNewPaymentMethod';
import RemoveStoredPaymentMethod from '../../components/StoredPaymentMethods/RemoveStoredPaymentMethod';

interface IProps {
  isSelectable: boolean;
  selectedPaymentMethodId?: string;
  setSelectedPaymentMethodId?: React.Dispatch<SetStateAction<string>>;
}

const StoredPaymentMethod = ({
  isSelectable,
  selectedPaymentMethodId,
  setSelectedPaymentMethodId,
}: IProps) => {
  const theme = useTheme();
  const grey: ColorPartial = theme.palette.grey as ColorPartial;
  const [dialogStatus, setDialogStatus] = React.useState(false);

  const handleClickOpen = () => {
    setDialogStatus(true);
  };
  const handleClose = () => {
    setDialogStatus(false);
  };

  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<string | null>(null);
  const [selectedPaymentMethodIdToDelete, setPaymentMethodId] = useState<string | null>(null);

  const dispatch: StoreDispatch = useDispatch();
  const {
    paymentMethods,
    autoPayStatus,
    autoPayError,
    defaultStoredPaymentStatus,
    removeStoredPaymentStatus,
  } = useSelector((state: RootState) => state.autoPay);

  useEffect(() => {
    dispatch(fetchStoredPayments());
  }, []);
  useEffect(() => {
    if (removeStoredPaymentStatus === 'succeeded') {
      dispatch(fetchStoredPayments());
    }
  }, [removeStoredPaymentStatus]);

  useEffect(() => {
    return () => {
      dispatch(resetRemovePaymentStore());
    };
  }, []);

  const remove = (paymentTokenId: string) => {
    setCurrentPaymentMethod(paymentTokenId);
    dispatch(removeStoredPayment(paymentTokenId));
  };

  const handleClickOpenRemoveStoredPaymentMethod = (paymentMethodId: IPaymentMethod) => {
    const paymentId = paymentMethodId.spmId;
    setPaymentMethodId(paymentId);
    handleClickOpen();
  };

  const renderRadioOrText = (paymentMethod: IPaymentMethod) => {
    const Label = () => (
      <Box
        display='flex'
        alignItems='center'
        gap={theme.spacing(2)}
        sx={{
          color: grey[500],
        }}
      >
        <MethodDescriptionAsPerBrand type={paymentMethod.paymentMethod} data={paymentMethod} />
      </Box>
    );
    if (isSelectable) {
      return (
        <FormControlLabel
          checked={selectedPaymentMethodId === paymentMethod.spmId}
          control={
            <Radio
              size='small'
              onChange={() => setSelectedPaymentMethodId?.(paymentMethod.spmId)}
            />
          }
          label={<Label />}
          componentsProps={{
            typography: {
              fontWeight: 700,
            },
          }}
        />
      );
    }

    return <Label />;
  };

  const renderPaymentMethods = () => {
    if (autoPayStatus === 'loading') {
      return <CircularProgress />;
    }

    if (autoPayStatus === 'failed') {
      return <ErrorPage title='Something went wrong' message={autoPayError} />;
    }

    if (!paymentMethods?.length) {
      return (
        <Typography color='info' my={theme.spacing(10)} variant='body2'>
          You don&apos;t have any Payment methods added to the wallet. Please add a new payment
          method
        </Typography>
      );
    }

    return paymentMethods.map((paymentMethod) => (
      <Card
        key={paymentMethod.spmId}
        sx={{
          border: `${theme.spacing(0.5)} solid ${grey[200]}`,
          width: '100%',
        }}
      >
        <CardContent>
          <Box display='flex' justifyContent='space-between'>
            <Box>
              {renderRadioOrText(paymentMethod)}
              <Box mt={theme.spacing(2)} ml={theme.spacing(isSelectable ? 13 : 0)}>
                <Button
                  sx={{ minWidth: 'auto' }}
                  onClick={() => handleClickOpenRemoveStoredPaymentMethod(paymentMethod)}
                >
                  {removeStoredPaymentStatus === 'loading' &&
                  paymentMethod.spmId === currentPaymentMethod ? (
                    <CircularProgress size={16} />
                  ) : (
                    <Typography variant='underline' color={grey[500]}>
                      Remove
                    </Typography>
                  )}
                </Button>
              </Box>
              {defaultStoredPaymentStatus === 'failed' &&
                paymentMethod.spmId === currentPaymentMethod && (
                  <Typography mt={theme.spacing(2)} variant='paragraph2' color='error'>
                    Failed to Set Payment Method as Default
                  </Typography>
                )}
              {removeStoredPaymentStatus === 'failed' &&
                paymentMethod.spmId === currentPaymentMethod && (
                  <Typography mt={theme.spacing(2)} variant='paragraph2' color='error'>
                    Failed to Remove the Payment Method
                  </Typography>
                )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    ));
  };

  return (
    <>
      <Stack
        gap={theme.spacing(4)}
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{
          minHeight: '120px',
        }}
      >
        {renderPaymentMethods()}
      </Stack>
      <AddNewPaymentMethod />
      <RemoveStoredPaymentMethod
        open={dialogStatus}
        close={handleClose}
        selectedPaymentMethodIdToDelete={selectedPaymentMethodIdToDelete}
        remove={remove}
      />
    </>
  );
};

export default StoredPaymentMethod;
